import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "100px 15px",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
  inner: {
    maxWidth: "1170px",
  },
  textIntro: {
    fontSize: "14px",
    padding: "10px 0px",
    marginBottom: "0px",
    display: "block",
  },
  section: {
    width: '100%',
    paddingRight: "15px"
  },
  sectionHolder: {
    display: 'flex'
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  }
}));

const AboutUsFord = () => {

  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            About Us
          </Typography>
          <div className={classes.sectionHolder}>
            <div className={classes.section}>

              <Typography variant="p" className={classes.textIntro}>
              Welcome to Ben Morgenrood Ford, a proud member of the renowned Ben Morgenrood Group. With deep roots in
              Randfontein, we’ve been a cornerstone of the community for many years. Our iconic building on Main Reef Road stands as a
              symbol of our unwavering commitment to exceptional service and a comprehensive range of Ford vehicles.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              "For years, Ben Morgenrood Ford has shaped the automotive experience in the region, earning a reputation for quality,
                professionalism, and a diverse selection of Ford models. From dynamic city cars to robust pickup trucks, our showroom
                caters to every lifestyle and need.
              </Typography>

              <Typography variant="p" className={classes.textIntro}>
              Beyond sales, we offer dedicated on-site workshops and a parts department, staffed by skilled professionals. Whether it's
              routine maintenance, repairs, or genuine Ford parts, our team is here to ensure your vehicle performs at its best.
              Join us at Ben Morgenrood Ford, where Ford’s legacy continues to shine, and our commitment to excellence drives us every
              day."
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsFord;
